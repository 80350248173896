import React, { useState, useEffect } from 'react';
import './simple-table.styles.scss';

const SimpleTable = () => {
  const [formData, setFormData] = useState({
    'denumire-firma': '',
    'judet-firma': '',
    'localitate-firma': '',
    'cp-firma': '',
    'str-firma': '',
    'nr-firma': '',
    'bloc-firma': '',
    'scara-firma': '',
    'etaj-firma': '',
    'ap-firma': '',
    'nume-proiectant': '',
    'nr-tna': '',
    'filiala-oar-proiectant': ''
  });

  useEffect(() => {
    const savedData = localStorage.getItem('formData');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value
    });
  };

  const handleBlur = () => {
    localStorage.setItem('formData', JSON.stringify(formData));
  };

  return (
    <form>
      <input type="text" id="denumire-firma" value={formData['denumire-firma']} onChange={handleChange} onBlur={handleBlur} />
      <input type="text" id="judet-firma" value={formData['judet-firma']} onChange={handleChange} onBlur={handleBlur} />
      <input type="text" id="localitate-firma" value={formData['localitate-firma']} onChange={handleChange} onBlur={handleBlur} />
      <input type="text" id="cp-firma" value={formData['cp-firma']} onChange={handleChange} onBlur={handleBlur} />
      <input type="text" id="str-firma" value={formData['str-firma']} onChange={handleChange} onBlur={handleBlur} />
      <input type="text" id="nr-firma" value={formData['nr-firma']} onChange={handleChange} onBlur={handleBlur} />
      <input type="text" id="bloc-firma" value={formData['bloc-firma']} onChange={handleChange} onBlur={handleBlur} />
      <input type="text" id="scara-firma" value={formData['scara-firma']} onChange={handleChange} onBlur={handleBlur} />
      <input type="text" id="etaj-firma" value={formData['etaj-firma']} onChange={handleChange} onBlur={handleBlur} />
      <input type="text" id="ap-firma" value={formData['ap-firma']} onChange={handleChange} onBlur={handleBlur} />
      <input type="text" id="nume-proiectant" value={formData['nume-proiectant']} onChange={handleChange} onBlur={handleBlur} />
      <input type="text" id="nr-tna" value={formData['nr-tna']} onChange={handleChange} onBlur={handleBlur} />
      <input type="text" id="filiala-oar-proiectant" value={formData['filiala-oar-proiectant']} onChange={handleChange} onBlur={handleBlur} />
    </form>
  );
};

export default SimpleTable;
